@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Main Header */
.background-radial-gradient {
  /* background-color: hsl(218, 41%, 15%); */
  background-color: hsl(218deg 12% 18%);
    background-image: radial-gradient( 650px circle at 0% 0%, hsl(353deg 86% 81% / 45%) 15%, hsl(218, 41%, 30%) 35%, hsl(218, 41%, 20%) 75%, hsl(218, 41%, 19%) 80%, transparent 100% ), radial-gradient( 1250px circle at 100% 100%, hsl(218deg 12% 18%) 15%, hsl(218deg 12% 18%) 35%, hsl(218deg 12% 18%) 75%, hsl(218deg 12% 18%) 80%, transparent 100% );
}
#radius-shape-1 {
  height: 220px;
  width: 220px;
  top: -60px;
  left: -130px;
  background: radial-gradient(#000000, #f8a5af);
  overflow: hidden;
}
#radius-shape-2 {
  border-radius: 38% 62% 63% 37% / 70% 33% 67% 30%;
  bottom: -60px;
  right: -110px;
  width: 300px;
  height: 300px;
  background: radial-gradient(#040304, #7dd3fc);
  overflow: hidden;
}
.bg-glass {
  background-color: hsl(0deg 0% 0% / 40%);
  backdrop-filter: saturate(200%) blur(25px);
}

.markdown-para h2, .markdown-para h3, .markdown-para h4 {
  margin: 0.5rem 0;
  font-weight: 600;
  line-height: 1.2;
}

.markdown-para h4 {
  font-size: 1.2rem;
}


/* Overwriting daisy (primarily fixes) */
.badge {
    line-height: inherit;
}