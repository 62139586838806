.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-body {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.maintitle {
  font-size: 5rem;
  white-space: nowrap;
}

.text-gradient {
  color: transparent;
  letter-spacing: -0.025em;
  -webkit-background-clip: text;
  background-clip: text;
  --tw-gradient-to: #db2777;
  --tw-gradient-from: #c084fc;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgb(192 132 252 / 0));
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}

/* handle small screens */
@media (max-width: 600px) {
  .maintitle {
    font-size: 3rem;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2023-3-8 14:56:38
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
.tracking-in-expand {
	-webkit-animation: tracking-in-expand 0.7s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
	        animation: tracking-in-expand 0.7s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
}

@-webkit-keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
@keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}

.link-primary {
    text-decoration: none;
    position: relative;
    transition: unset;
    opacity: 1;
    border-bottom-width: 0.1rem;
    background: transparent;
    background-origin: border-box;
    background-repeat: no-repeat;
    background-position: 50% 100%;
    background-size: 0 0.1rem;
}

.link-primary:hover {
    border-bottom-color: transparent;
    background-image: linear-gradient(90.68deg,#b439df .26%,#e5337e 102.37%);
    background-repeat: no-repeat;
    background-position: 0 100%;
    background-size: 100% 0.1rem;
    transition-property: background-position,background-size;
    transition-duration: .3s;
}